let Vue = window.Vue;
let pageData = window.pageData() || {};

Vue.component('grid-data', {
    template: `
        <div class="grid-data">
            <div v-for="(rows, row) in getPageRows"
                :key="'row-' + row"
                :style="rowStyle"
                 class="item-row" flex="cross:stretch box:mean">
                <div v-for="(item, col) in rows"
                    :key="'col-' + col"
                    :style="colStyle"
                     class="item-col">
                     <template v-if="item">
                        <slot :row="row" :col="col" :item="item"></slot>
                    </template>
                </div>
            </div>
        </div>
    `,
    props: {
        pageRows: {
            type: Array,
            required: true
        },
        hgap: {
            type: Number,
            default: 0,
            validator: function (v) {
                return v >= 0;
            }
        },
        vgap: {
            type: Number,
            default: 0,
            validator: function (v) {
                return v >= 0;
            }
        }
    },
    computed: {
        rowStyle () {
            if (this.hgap) {
                return {
                    'margin-top': `${this.vgap}px`,
                    'margin-left': `${-this.hgap / 2}px`,
                    'margin-right': `${-this.hgap / 2}px`
                };
            }
        },
        colStyle () {
            if (this.hgap) {
                return {
                    'padding-left': `${this.hgap / 2}px`,
                    'padding-right': `${this.hgap / 2}px`
                };
            }
        },
        getPageRows () {
            return this.pageRows.filter(rows => rows.some(row => !!row));
        }
    }
});
Vue.component('page-grid', {
    template: `
        <div class="page-grid">
            <div class="header" flex="main:justify cross:bottom">
                <div class="page-head">
                    <div class="title">
                        <span class="text">{{title}}</span>
                        <span v-if="subtitle" class="subtitle">{{subtitle}}</span>
                    </div>
                    <template v-if="filters && filters.length">
                        <div class="filters" flex="cross:center">
                            <div class="filter"
                                :class="{'is-active': !selectFilter}"
                                @click="onSelectFilter(null)">全部</div>
                            <div v-for="(filter, index) in filters"
                                :key="'filter-' + index"
                                class="filter button"
                                :class="{'is-active': filter === selectFilter}"
                                @click="onSelectFilter(filter)">
                                <slot name="filter" :filter="filter">{{filter}}</slot>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="extra">
                    <div v-if="pageCount > 1"
                        class="page-nav">
                        <el-button type="default" icon="el-icon-arrow-left" :disabled="loading || pageIndex <= 0" @click="prevPage"></el-button>
                        <el-button type="default" icon="el-icon-arrow-right" :disabled="loading || pageIndex >= pageCount - 1" @click="nextPage"></el-button>
                    </div>
                </div>       
            </div>
            <div class="main">
                <transition :name="'animation-pull-' + pullStyle">
                    <grid-data :key="pageIndex" :page-rows="pageRows" :hgap="hgap" :vgap="vgap">
                        <template #default="scope">
                            <slot v-bind="scope"></slot>
                        </template>
                    </grid-data>
                </transition>
            </div>
            <div v-if="$slots.footer"
                class="footer">
                <slot name="footer"></slot>
            </div>
        </div>
    `,
    props: {
        title: {
            type: String,
            required: true
        },
        subtitle: String,
        pageResolve: {
            type: Function,
            required: true
        },
        totalCount: {
            type: Number,
            required: true,
        },
        row: {
            type: Number,
            default: 1,
            validator: function (r) {
                return r >= 1;
            }
        },
        col: {
            type: Number,
            default: 4,
            validator: function (r) {
                return r >= 1;
            }
        },
        hgap: {
            type: Number,
            default: 0,
            validator: function (v) {
                return v >= 0;
            }
        },
        vgap: {
            type: Number,
            default: 0,
            validator: function (v) {
                return v >= 0;
            }
        },
        filters: Array
    },
    data () {
        return {
            pageIndex: 0,
            selectFilter: null,
            pullStyle: '',
            loading: false,
            dataList: []
        };
    },
    computed: {
        pageCount () {
            return Math.ceil(this.totalCount / (this.row * this.col));
        },
        pageRows () {
            let dataList = this.dataList || [];
            let pageRows = [];
            for (let r = 0; r < this.row; r ++) {
                let row = [];
                for (let c = 0; c < this.col; c ++) {
                    row[c] = dataList[r * this.col + c];
                }
                pageRows.push(row);
            }
            return pageRows;
        },
        dataResolve () {
            return this.pageResolve(this.pageIndex, this.row * this.col, this.selectFilter);
        }
    },
    methods: {
        prevPage () {
            this.pageIndex --;
        },
        nextPage () {
            this.pageIndex ++;
        },
        onSelectFilter (filter) {
            this.selectFilter = filter;
        }
    },
    watch: {
        pageIndex (newIndex, oldIndex) {
            if (typeof newIndex === 'number' && typeof oldIndex === 'number') {
                this.pullStyle = newIndex < oldIndex ? 'left' : 'right';
            }
        },
        selectFilter () {
            this.pageIndex = 0;
        },
        dataResolve: {
            handler (val) {
                if (val) {
                    if (typeof val.then === 'function') {
                        this.loading = true;
                        val.then(dataList => {
                            this.dataList = dataList;
                            this.loading = false;
                        }, () => {
                            this.loading = false;
                        });
                    } else {
                        this.dataList = val;
                    }
                } else {
                    this.dataList = null;
                }
            },
            immediate: true
        }
    }
});
Vue.component('carousel-module', {
    template: `
        <div :class="{'module-carousel-container': moduleData.carouseType === '2'}">
            <el-carousel v-if="moduleData.carouselList && moduleData.carouselList.length"
                :class="{'module-carousel-body': moduleData.carouseType === '2'}"
                class="module-carousel"
                arrow="never"
                height="390px"
                :indicator-position="moduleData.carouselList.length > 1 ? '' : 'none'">
                <el-carousel-item v-for="(carousel, index) in moduleData.carouselList"
                    :key="'carousel-' + index">
                    <div class="poster">
                        <a :href="carousel.link ? carousel.link : null" :target="carousel.isBlank ? '_blank' : ''" v-lazy:background-image="carousel.imgUrl"></a>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
    `,
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            moduleData: this.data.data || {}
        };
    }
});
Vue.component('live-course-module', {
    template: `
        <page-grid v-if="lessonList && lessonList.length"
                   class="module-live-course"
                   :title="moduleData.title"
                   :subtitle="moduleData.subTitle"
                   :page-resolve="pageResolve"
                   :total-count="lessonList.length"
                   :hgap="20">
            <template #default="{item}">
                <div class="live-item" flex="dir:top box:last" >
                    <div class="item-main">
                        <div class="header">
                            <div v-if="item.status" class="status-bar" :class="['status-' + item.status.code, {'status-playback': item.isRecordPlay}]" flex="cross:stretch">
                                <div class="icon">
                                    <i class="iconfont" :class="getIcon(item)"></i>
                                </div>
                                <div class="text">{{item.status.name}}</div>
                            </div>
                        </div>
                        <div class="detail">
                            <div class="introduce">{{item.lessonName}}</div>
                            <div class="title">{{item.className}}</div>
                        </div>
                        <div class="teachers" flex="cross:center">
                            <div class="teacher">
                                <a class="head-portrait"
                                    v-lazy:background-image="item.teacherPhoto">
                                    <div class="tag">主讲</div>
                                </a>
                                <div class="true-name">{{item.teacherName}}</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="item.status"
                        class="item-footer">
                        <template v-if="item.status.code === '0'">
                            <el-button size="mini"
                                type="info"
                                round
                                disabled>未开始</el-button>
                        </template>
                        <template v-else-if="item.status.code === '1'">
                            <el-button size="mini"
                                type="success"
                                @click="toCoursePage(item)"
                                round>进入直播</el-button>
                        </template>
                        <template v-else-if="item.status.code === '2'">
                            <template v-if="item.isRecordPlay">
                                <el-button size="mini"
                                        type="primary"
                                         @click="toCoursePage(item)"
                                        round>查看回放</el-button>
                            </template>
                            <template v-else>
                                <el-button size="mini"
                                        type="info"
                                        round
                                        disabled>已结束</el-button>
                            </template>
                        </template>
                    </div>
                </div>
            </template>
        </page-grid>
    `,
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            moduleData: this.data.data || {},
            current: typeof pageData.currentTime === 'number' ? pageData.currentTime : window.moment().valueOf()
        };
    },
    computed: {
        lessonList () {
            let lessonList = this.moduleData.lessonList || [];
            let clock = window.moment(this.current);
            lessonList.forEach(lesson => {
                if (!lesson.liveStartTime) {
                    lesson.liveStartTime = window.moment(lesson.liveStartDate, 'YYYY-MM-DD HH:mm:ss');
                }
                if (!lesson.liveEndTime) {
                    lesson.liveEndTime = window.moment(lesson.liveEndDate, 'YYYY-MM-DD HH:mm:ss');
                }
                let status;
                if (clock.isBefore(lesson.liveStartTime)) {
                    let diffDays = lesson.liveStartTime.diff(window.moment(clock.format('YYYY-MM-DD')), 'day');
                    let dayTip;
                    switch (diffDays) {
                        case 0:
                            dayTip = '今天';
                            break;
                        case 1:
                            dayTip = '明天';
                            break;
                        case 2:
                            dayTip = '后天';
                            break;
                        default:
                            dayTip = lesson.liveStartTime.format('MM-DD');
                            break;
                    }
                    status = {
                        name: `${dayTip} ${lesson.liveStartTime.format('HH:mm')}-${lesson.liveEndTime.format('HH:mm')}`,
                        code: '0'
                    };
                } else if (clock.isBefore(lesson.liveEndTime)) {
                    status = {
                        name: '直播中',
                        code: '1'
                    };
                } else {
                    status = {
                        name: '已结束',
                        code: '2'
                    };
                }
                lesson.status = status;
            });
            return lessonList;
        }
    },
    mixins: [window.globalMixin, window.utilsMixin],
    methods: {
        pageResolve (pageIndex, pageSize) {
            return this.lessonList.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
        },
        getIcon (item) {
            switch (item.status.code) {
                case '0':
                    return 'icon-zuijinzhibo-yugaoshijian';
                case '1':
                    return 'icon-zuijinzhibo-zhibozhong';
                case '2': {
                    if (item.isRecordPlay) {
                        return 'icon-zuijinzhibo-yijieshu';
                    } else {
                        return 'icon-zuijinzhibo-yijieshu2';
                    }
                }
            }
        },
        toCoursePage (course) {
            this.toUrl(`/learning/courseDetail?courseId=${course.classId}#live`);
        }
    },
    created () {
        this.timer = setInterval(() => {
            this.current += 1000;
        }, 1000);
    },
    beforeDestroy () {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }
});
Vue.component('course-recommend-module', {
    template: `
        <page-grid v-if="courseRecommendList && courseRecommendList.length"
                   class="module-course-recommend"
                   :title="moduleData.title"
                   :subtitle="moduleData.subTitle"
                   :page-resolve="pageResolve"
                   :row="moduleData.rowCount"
                   :total-count="courseRecommendList.length"
                   :hgap="20"
                   :vgap="20">
            <template #default="{item}">
                <div class="course-item" flex="dir:top box:first"  @click="goCourseDetail(item.id)">
                    <div class="poster">
                        <a v-lazy:background-image="item.imgUrl"></a>
                        <div v-if="item.packageCount"
                            class="count">
                            <span>{{item.packageCount}}门课</span>
                        </div>
                    </div>
                    <div class="detail" flex="dir:top main:justify">
                        <div class="top">
                            <div class="title">{{item.name}}</div>
                        </div>
                        <div class="bottom">
                            <div class="introduce">{{item.subTitle}}</div>
                            <div class="label-name">
                                <span v-if="item.labelNameList" v-for="(label, index) in item.labelNameList" :key="index">{{label}}</span>
                            </div>
                            <div v-if="isShowPrice"
                                class="price">
                                <span class="cost">
                                    <span v-if="item.cost > 0"
                                        class="fee">{{item.cost}}</span>
                                    <span v-else-if="item.cost === 0"
                                        class="fee">免费</span>
                                    <span v-else>暂未定价</span>
                                </span>
                                <span v-if="item.primeCost"
                                    class="prime-cost">
                                    <span class="fee">{{item.primeCost}}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <a v-if="courseRecommendList.length > (moduleData.rowCount * 4)" class="view-more button" href="/learning/courseList">
                    <span>查看更多</span>
                   <i class="el-icon-arrow-right"></i>
                </a>
            </template>
        </page-grid>
    `,
    mixins: [window.globalMixin],
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            moduleData: this.data.data || {}
        };
    },
    computed: {
        courseRecommendList () {
            return this.moduleData.courseRecommendList || [];
        }
    },
    methods: {
        pageResolve (pageIndex, pageSize) {
            return this.courseRecommendList.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
        },
        goCourseDetail (courseId) {
            this.toUrl('/learning/courseDetail?courseId=' +courseId);
        }
    }
});
Vue.component('project-course-module', {
    template: `
        <page-grid v-if="moduleData.courseList && moduleData.courseList.length"
                   class="module-project-course"
                   :title="moduleData.title"
                   :subtitle="moduleData.subTitle"
                   :page-resolve="pageResolve"
                   :row="moduleData.rowCount"
                   :total-count="courses.length"
                   :hgap="20"
                   :vgap="20"
                   :filters="filters">
            <template #default="{item}">
                <div class="course-item" flex="dir:top box:first" @click="goCourseDetail(item.id)">
                    <div class="poster">
                        <a v-lazy:background-image="item.imgUrl"></a>
                        <div v-if="item.packageCount"
                            class="count">
                            <span>{{item.packageCount}}门课</span>
                        </div>
                    </div>
                    <div class="detail" flex="dir:top main:justify">
                        <div class="top">
                            <div class="title">{{item.name}}</div>
                        </div>
                        <div class="bottom">
                            <div class="introduce">{{item.subTitle}}</div>
                            <div class="label-name">
                                <span v-if="item.labelNameList" v-for="(label, index) in item.labelNameList" :key="index">{{label}}</span>
                            </div>
                            <div v-if="isShowPrice"
                                class="price">
                                <span class="cost">
                                    <span v-if="item.cost > 0"
                                        class="fee">{{item.cost}}</span>
                                    <span v-else-if="item.cost === 0"
                                        class="fee">免费</span>
                                    <span v-else>暂未定价</span>
                                </span>
                                <span v-if="item.primeCost"
                                    class="prime-cost">
                                    <span class="fee">{{item.primeCost}}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #filter="{filter}">
                <span>{{filter.name}}</span>
            </template>
            <template #footer>
                <a v-if="courses.length > (moduleData.rowCount * 4)"  class="view-more button" :href="'/learning/courseList?projectId=' + moduleData.projectId + (filter ? '&majorId=' + filter.id: '')">
                    <span>查看更多</span>
                   <i class="el-icon-arrow-right"></i>
                </a>
            </template>
        </page-grid>
    `,
    mixins: [window.globalMixin],
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            moduleData: this.data.data || {},
            courseList: null,
            filter: null
        };
    },
    computed: {
        filters () {
            return this.moduleData.majorList || [];
        },
        courses () {
            let courseList = this.courseList;
            if (courseList) {
                return courseList;
            }
            return this.moduleData.courseList || [];
        }
    },
    methods: {
        pageResolve (pageIndex, pageSize, filter) {
            if (this.filter === filter) {
                return this.courses.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
            } else {
                this.filter = filter;
                return new Promise(resolve => {
                    window.axios({
                        url: '/index/templatePage/getHomeCourseByMajor',
                        method: 'post',
                        data: window.Qs.stringify({
                            projectId: this.moduleData.projectId,
                            majorId: filter ? filter.id: ''
                        })
                    }).then(res => {
                        if(res.data.success) {
                            this.courseList = res.data.courseList || [];
                            resolve(this.courseList.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize));
                        } else {
                            this.courseList = [];
                            resolve([]);
                        }
                    }, () => {
                        this.courseList = [];
                        resolve([]);
                    });
                });
            }
        },
        goCourseDetail (courseId) {
            this.toUrl('/learning/courseDetail?courseId=' +courseId);
        }
    }
});
Vue.component('teacher-recommend-module', {
    template: `
        <page-grid v-if="teacherList && teacherList.length"
                   class="module-teacher-recommend"
                   :title="moduleData.title"
                   :subtitle="moduleData.subTitle"
                   :page-resolve="pageResolve"
                   :total-count="teacherList.length"
                   :hgap="20">
            <template #default="{item}">
                <div class="teacher-item" flex="dir:top">
                    <div class="detail">
                        <a class="head-portrait"
                            v-lazy:background-image="item.showPhotoLink"></a>
                        <div class="true-name">{{item.name}}</div>
                        <div class="tag">{{item.orient}}</div>
                    </div>
                    <div v-if="item.note"
                        class="introduce">{{item.note}}</div>
                </div>
            </template>
        </page-grid>
    `,
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    mixins: [window.globalMixin],
    data () {
        return {
            moduleData: this.data.data || {}
        };
    },
    computed: {
        teacherList () {
            return this.moduleData.teacherList || [];
        }
    },
    methods: {
        pageResolve (pageIndex, pageSize) {
            return this.teacherList.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
        }
    }
});
Vue.component('news-module', {
    template: `
        <div v-if="newsList && newsList.length" class="module-news">
            <div class="header">
                <div class="title">
                    <span class="text">{{moduleData.title}}</span>
                    <span class="subtitle">{{moduleData.subTitle}}</span>
                </div>
            </div>
            <div class="main" flex="cross:stretch box:first">
                <div class="poster-news"  @click="toNewsDetail(posterNews)">
                    <template v-if="posterNews">
                        <div class="poster">
                            <a class="img"
                                v-lazy:background-image="posterNews.imgUrl"></a>
                            <div v-if="posterNews.createDate" class="date" flex="dir:top main:center cross:center">
                                <div class="day">{{posterNews.createDate.split(' ')[0].split('-').slice(-1).join('')}}</div>
                                <div class="period">
                                    {{posterNews.createDate.split(' ')[0].split('-').slice(0, -1).join('-')}}
                                </div>
                            </div>
                            <div class="detail" flex="dir:top box:first">
                                <a class="title">{{posterNews.title}}</a>
                                <div class="introduce">{{posterNews.subTitle}}</div>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="listed-news" flex="dir:top box:mean" >
                    <template v-if="listedNews && listedNews.length">
                        <div class="news-item" v-for="index in 3"
                            :key="'news-' + index" flex="main:top box:first"
                            @click="toNewsDetail(listedNews[index - 1])">
                            <template v-if="listedNews[index - 1]">
                                <div v-if="listedNews[index - 1].createDate" class="date" flex="dir:top main:center cross:center">
                                    <div class="day">{{listedNews[index - 1].createDate.split(' ')[0].split('-').slice(-1).join('')}}</div>
                                    <div class="period">
                                        {{listedNews[index - 1].createDate.split(' ')[0].split('-').slice(0, -1).join('-')}}
                                    </div>
                                </div>
                                <div class="detail">
                                    <a class="title">{{listedNews[index - 1].title}}</a>
                                    <div class="introduce">{{listedNews[index - 1].subTitle}}</div>
                                    <div class="more">
                                        <span class="view-count">{{listedNews[index - 1].viewsCount || 0}}人浏览</span>
                                        <span class="tag">{{listedNews[index - 1].cName}}</span>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </template>
                </div>
            </div>
            <div class="footer">
                <a v-if="data.data.newsList.length > 4" class="view-more button" @click="toNewsPage">
                    <span>查看更多</span>
                   <i class="el-icon-arrow-right"></i>
                </a>
            </div>
        </div>
    `,
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            moduleData: this.data.data || {}
        };
    },
    mixins: [window.globalMixin, window.utilsMixin],
    computed: {
        newsList () {
            return this.moduleData.newsList || [];
        },
        posterNews () {
            let newsList = this.newsList;
            if (newsList && newsList.length) {
                return newsList[0];
            }
        },
        listedNews () {
            let newsList = this.newsList;
            if (newsList && newsList.length) {
                return newsList.slice(1, 4);
            }
        }
    },
    methods: {
        toNewsPage () {
            this.openUrl(`/learning/homeNewsList?homeNewsId=${this.moduleData.homeNewsId}`);
        },
        toNewsDetail (news) {
            this.openUrl(`/learning/homeNewsDetail?newsId=${news.id}`);
        }
    }
});
Vue.component('site-module', {
    template: `
        <page-grid v-if="siteList && siteList.length"
                   class="module-site"
                   :title="moduleData.title"
                   :subtitle="moduleData.subTitle"
                   :page-resolve="pageResolve"
                   :row="moduleData.rowCount"
                   :col="5"
                   :total-count="siteList.length"
                   :hgap="20">
            <template #default="{item}">
                <div class="site-item">
                    <a class="logo"
                        :title="item.name"
                        :href="item.link ? item.link : null"
                        :target="item.isBlank ? '_blank' : ''"
                        v-lazy:background-image="item.imgUrl"></a>
                </div>
            </template>
        </page-grid>
    `,
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            moduleData: this.data.data || {}
        };
    },
    computed: {
        siteList () {
            return this.moduleData.siteList || [];
        }
    },
    methods: {
        pageResolve (pageIndex, pageSize) {
            return this.siteList.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
        }
    }
});
Vue.component('home-img-module', {
    template: `
        <el-carousel v-if="moduleData.imgList && moduleData.imgList.length"
            class="module-home-img"
            arrow="never"
            height="84px"
            :indicator-position="moduleData.imgList.length > 1 ? '' : 'none'">
            <el-carousel-item v-for="(img, index) in moduleData.imgList"
                :key="'carousel-' + index">
                <div class="poster">
                    <a :href="img.link ? img.link : null" :target="img.isBlank ? '_blank' : ''" v-lazy:background-image="img.imgUrl"></a>
                </div>
            </el-carousel-item>
        </el-carousel>
    `,
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            moduleData: this.data.data || {}
        };
    }
});
Vue.component('template-module', {
    template: `
        <div class="template-module">
            <component :is="getModule" :data="data"></component>
        </div>
    `,
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        getModule () {
            switch (this.data.type) {
                case 'carousel':
                    return 'carousel-module';
                case 'courseRecommend':
                    return 'course-recommend-module';
                case 'projectCourse':
                    return 'project-course-module';
                case 'liveCourse':
                    return 'live-course-module';
                case 'teacherRecommend':
                    return 'teacher-recommend-module';
                case 'news':
                    return 'news-module';
                case 'site':
                    return 'site-module';
                case 'homeImg':
                    return 'home-img-module';
            }
        }
    }
});

new Vue({
    el: '#content',
    data () {
        return pageData;
    }
});
